<template>
  <div class="carousel">
    <el-carousel
      :interval="4000"
      indicator-position="false"
      type="card"
      :height="height+'px'"
    >
      <el-carousel-item v-for="(item , index) in imgSrc" :key="index">
        <img :src="item.src" alt="" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  props:{
    imgSrc:String,
    height:Number
  },
  data() {
    return {

    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="less">
</style>
<style scoped>
.carousel {
  width: 540px;
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>

<template>
  <div class="content">
    <!-- :style="{ height: screenHeight + 'px' } -->
    <div class="banner" >
      <img src="../assets/image/banner.png" alt="">
      <!-- <div class="bannerInfo">
        <div>
          <span>愉与遇 </span>
          --- 很愉快与你相遇
        </div>
        <div><p>一个服务于打工人的社交平台</p></div>
        <div>
          <p>漂泊异地没朋友，没机会认识异性朋友，来这里结识更</p>
          <p>多小伙伴吧！</p>
        </div>
        <div>
          <p>可以线上单身交友，也可以组织参加线下活动，一起扩</p>
          <p>大朋友圈吧！</p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "GudaoctWebsiteBanner",

  data() {
    return {
      screenHeight: 0,
    };
  },

  mounted() {
    let bodyHeight = window.screen.availHeight;
    this.screenHeight = bodyHeight - 98;
    console.log(this.screenHeight);
  },

  methods: {},
  created() {

  },
};
</script>

<style lang="less" scoped>
.banner {
  // position: relative;
  overflow: hidden;
  margin-top: -5px;
  width: 100%;
  // background-image: url("../assets/image/banner.png");
  // background-size: 100% 100%;
  img{
    width: 100%;
    height: 100%;
  }
}
.bannerInfo {
  position: absolute;
  bottom: 350px;
  right: 50px;
  height: 400px;
  width: 1000px;
  color: #fff;
  text-align: center;
  line-height: 100px;

  span {
    display: block;
    width: 200px;
    background-color: #f6a50e;
    color: #000;
  }
  div:nth-child(1) {
    height: 73px;
    line-height: 73px;
    font-size: 60px;
    display: flex;
    justify-content: center;
  }
  div:nth-child(2) {
    font-size: 42px;
  }
  div:nth-child(3) {
    font-size: 33px;
  }
  div:nth-child(4) {
    font-size: 33px;
  }
  p:nth-child(2){
    line-height: 50px !important;
  }
}
</style>
<template>
  <div class="content">
    <el-container>
      <!-- 头部 -->
      <div :class="[scroll >= 200 ? 'headState' : 'head']">
        <div class="logo"></div>
        <div class="headTitle">
          <div
            class="title"
            v-for="(item, index) in headTitle"
            :key="item.id"
            @click="goPaage(item.pagePath, index)"
          >
            <div :class="active == index ? 'activeTitle' : ''">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <!-- banner -->
      <div class="warp" id="home">
        <banner></banner>
      </div>
      <el-main>
        <div style="height: 60px" id="product"></div>
        <div class="productService">产品服务</div>
        <!-- 线上交友 -->
        <div class="onLineWarp">
          <div class="onLineInfo">
            <div class="infoHead">线上交友</div>
            <div
              class="info animate__animated animate__backInLeft"
              v-for="(item, index) in onLineList"
              :key="index + 2"
            >
              <div class="icon">
                <img :src="item.img" alt="" />
              </div>
              <div class="text">
                <p>{{ item.head }}</p>
                <p>{{ item.text }}</p>
              </div>
            </div>
          </div>
          <div class="onLineSwiper">
            <onLine :imgSrc="offlineSwiperlist" :height="520"></onLine>
          </div>
        </div>
        <!-- 线下活动 -->
        <div class="offlineWarp">
          <div class="offlineSwiper">
            <onLine :imgSrc="onLineSwiperlist" :height="500"></onLine>
          </div>
          <div class="offlineInfo">
            <div class="onLineInfo">
              <div class="infoHead">线下活动</div>
              <div
                class="info animate__animated animate__backInRight"
                v-for="(item, index) in offlineList"
                :key="index"
              >
                <div class="icon">
                  <img :src="item.img" alt="" />
                </div>
                <div class="text">
                  <p>{{ item.text }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 关于我们 -->
        <div class="aboutWarp" id="about_us">
          <div>关于我们</div>
          <div>
            我们是一支年轻的团队，在陌生人社交的方向上不断地探索，坚信互联网可以拉近人们距离，也可以满足人们的物质需求和精神需求。不断探索社群、社区、社会发展过程中，人与人之间的关系，向着实现社会和谐，达成构造人类命运共同体的目标前进。该项目为职场真实社交类产品，致力于为在一二线城市漂泊的打工人提供一个交友平台。
          </div>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer style="height: 120px">
        <div class="companyInfo" id="contact_us">
          <p>地址:北京市朝阳区朝阳门外大街甲6号万通中心AB座401</p>
          京公网安备:<a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502047655"
            >11010502047655</a
          >
          <a href="https://beian.miit.gov.cn/">京ICP备2021033157号-2</a>
          <a href="">&copy; 2021 古道长廷</a>
          <div>
            <span> 违法和不良信息举报电话 : 010 6848 8884</span>
            <span>消费者维权/客户服务/青少年守护专线 : 010 6848 8884</span>
            <span> 举报邮箱:contact@gudaochangting.com</span>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import onLine from "../components/onLine.vue";
import banner from "../components/banner.vue";

export default {
  name: "GudaoctWebsiteA",

  data() {
    return {
      onLineList: [
        {
          id: 1,
          img: require("../assets/image/icon/xinxi.png"),
          head: "用户信息人工审核",
          text: "用户进行企业身份认证和学历认证；",
        },
        {
          id: 2,
          img: require("../assets/image/icon/xuanze.png"),
          head: "用户始终拥有主动选择权",
          text: "主动喜欢或者主动拒绝，互相喜欢或接受喜欢后，牵手功成功后，可获取彼此联系方式；",
        },
        {
          id: 3,
          img: require("../assets/image/icon/dujue.png"),
          head: "杜绝海王行为",
          text: "当用户在牵手中时，不可以再次牵手；解除牵手状态后，才可以继续牵手；",
        },
      ],
      offlineList: [
        {
          id: 1,
          img: require("../assets/image/icon/dingdan.png"),
          text: "活动信息人工审核，避免发布违法违规 行为；",
        },
        {
          id: 2,
          img: require("../assets/image/icon/zhuti.png"),
          text: "用户可发起不同主题的活动，满足不同兴趣爱好人群；",
        },
        {
          id: 3,
          img: require("../assets/image/icon/xianxia.png"),
          text: "线下活动更真实，结识不同行业小伙伴，扩大朋友圈，扩展知识面；",
        },
      ],
      headTitle: [
        {
          id: 1,
          title: "首页",
          pagePath: "#home",
        },
        {
          id: 2,
          title: "产品",
          pagePath: "#product",
        },
        {
          id: 3,
          title: "关于我们",
          pagePath: "#about_us",
        },
        {
          id: 4,
          title: "联系我们",
          pagePath: "#contact_us",
        },
      ],
      onLineSwiperlist: [
        {
          id: 1,
          src: require("../assets/image/activity/5.jpg"),
        },
        {
          id: 2,
          src: require("../assets/image/activity/4.jpg"),
        },
        {
          id: 3,
          src: require("../assets/image/activity/1.jpg"),
        },
        {
          id: 4,
          src: require("../assets/image/activity/2.jpg"),
        },
        {
          id: 5,
          src: require("../assets/image/activity/3.jpg"),
        },
      ],
      offlineSwiperlist: [
        {
          title: 1,
          src: require("../assets/image/onLine/1.jpg"),
        },
        {
          title: 2,
          src: require("../assets/image/onLine/2.jpg"),
        },
        {
          title: 3,
          src: require("../assets/image/onLine/3.jpg"),
        },
      ],
      scroll: "",
      active: "",
    };
  },

  mounted() {
    window.addEventListener("scroll", this.scrollTop);
  },
  components: {
    banner,
    onLine,
  },

  methods: {
    goPaage(selector, index) {
      let anchor = this.$el.querySelector(selector);
      anchor.scrollIntoView();
      this.active = index;
    },
    scrollTop() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      console.log(this.scroll);
    },
  },
  created() {},
};
</script>

<style lang="less" scoped>
// 头部
.head {
  height: 60px;
  line-height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  color: #fff;
  z-index: 9999;

  .logo {
    float: left;
    width: 50px;
    height: 50px;
    background-image: url("../assets/image/logo.png");
    background-size: 100% 100%;
    margin-left: 150px;
    margin-top: 5px;
  }
  .headTitle {
    float: right;
    .title {
      width: 150px;
      float: left;
    }
    .title:hover {
      color: #f6a50e;
      font-weight: 800;
    }
  }
}
.activeTitle {
  color: #f6a50e;
  font-weight: 800;
}
.warp {
  background-color: #1a2425;
}
// 头部背景色
.headState {
  background-image: linear-gradient(to right, #7f8585, #1a2425);

  box-shadow: 0 0px 5px 1px #1a2425; //边框阴影
  height: 60px;
  line-height: 60px;
  position: fixed;
  width: 100%;
  top: 0;
  color: #fff;
  z-index: 9999;
  transition: height 2s;
  -webkit-transition: height 2s; /* Safari */
  .headTitle {
    display: flex;
    justify-content: flex-end;

    .title {
      width: 150px;
    }
    .title:hover {
      color: #f6a50e;
      font-weight: 800;
    }
  }
}

// 底部
.el-footer {
  background: #39454b;
  color: #ffffff;
  display: flex;
  font-size: 15px;
  text-align: center;
  align-items: center;
  .companyInfo {
    line-height: 30px;
    width: 100%;
    a {
      color: #ffffff;
      margin: 0 10px;
    }

    a:hover {
      color: #f6a50e;
    }
    span {
      margin: 0 10px;
    }
  }
  a:nth-child(2) {
    margin-left: 0px;
  }
}
// 主题
.el-main {
  background-color: #fff;
  color: #333;
  // text-align: center;
  // 产品服务
  .productService {
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 800;
    color: #333333;
    line-height: 86px;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 100px;
  }
  // 信息介绍
  .onLineWarp {
    display: flex;
    margin-top: 50px;
    justify-content: center;
  }

  .onLineInfo {
    .infoHead {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 65px;
      letter-spacing: 1px;
      margin-top: 60px;
      margin-bottom: 60px;
    }
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      // background-color: rgb(211, 45, 45);
      .icon {
        width: 32px;
        height: 32px;
        margin-right: 20px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .text {
        width: 300px;
        line-height: 25px;
        // background-color: rgb(145, 214, 16);
        p:nth-child(1) {
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 800;
          color: #333333;
        }
        p:nth-child(2) {
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
    }
  }

  .offlineWarp {
    display: flex;
    justify-content: center;
    // background-color: rgb(27, 180, 180);
    margin: 0 auto;
    margin: 150px 0;

    .offlineSwiper {
      display: flex;
      // background-color: rgb(153, 192, 14);
    }
  }
  .offlineInfo {
    margin-left: 100px;
  }
  .onLineInfo {
    margin-right: 100px;
  }

  // 关于我们
  .aboutWarp {
    display: flex;
    height: 130px;
    // background-color: rgb(170, 42, 42);
    justify-content: center;
    margin: 50px 0;
    div:nth-child(1) {
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 800;
      color: #333333;
      line-height: 130px;
      letter-spacing: 1px;
      width: 400px;
      // background-color: rgb(34, 99, 185);
      padding-left: 100px;
      box-sizing: border-box;
    }
    div:nth-child(2) {
      text-indent: 30px;
      width: 40%;
      height: 130px;
      // background-color: rgb(11, 196, 134);
      line-height: 30px;
    }
  }
}
</style>